import * as React from "react";
import Layout from "../components/Layout/Layout";
import { injectIntl } from "gatsby-plugin-intl";
import parse from "html-react-parser";
// @ts-ignore
import QR from "../images/QR_code.svg";
import ApplicationItem from "../components/ApplicationItem";
import BreadCrumbs from "../BreadCrumbs";

//@ts-ignore
const basket = ({ intl, pageContext }) => {
  return (
    <Layout>
      <div className="flex items-center justify-center bg-[#FAFAFA] ">
        <div className="mb-[10rem] flex flex-col  xl:max-w-[1200px] xl:w-[1200px] lg:max-w-[1000px] lg:w-[1000px] sm:max-w-[680px] sm:w-[680px] xs:max-w-[300px] xs:w-[300px]">
          <div className="ml-[0.2rem] mt-[2rem] mb-[2rem] ">
            <BreadCrumbs
              title="ApplicationForm.Application.Title.YourApplication"
              pageContext={pageContext}
            />
          </div>
          <h2 className=" text-[1.75rem] font-semibold text-[#001439] ">
            {parse(
              intl.formatMessage({
                id: "ApplicationForm.Application.Title.YourApplication",
              })
            )}
            <span className="ml-[0.3rem]">№ 000000001</span>
            <span className="ml-[0.3rem]">
              {parse(
                intl.formatMessage({
                  id: "ApplicationForm.Application.Title.Accepted",
                })
              )}
            </span>
          </h2>
          <h3 className="mt-[2rem] font-semibold text-[1.5rem]">
            {parse(
              intl.formatMessage({
                id: "ApplicationForm.Application.Subtitle",
              })
            )}
          </h3>

          <div className="flex flex-col xs:flex-col bg-white rounded-[1rem] lg:w-[48rem] sm:w-[42rem] xs:w-[18rem] pt-[2rem] lg:pl-[1.5rem] pr-[2rem] xs:pl-[1.2rem] pb-[2.5rem]  sm:mt-[2rem] xs:mt-[1rem]">
            <div className="flex sm:flex-row xs:flex-col-reverse">
              <div className="w-[70%]">
                <h2 className="font-semibold text-[1.75rem]">
                  {parse(
                    intl.formatMessage({
                      id: "ApplicationForm.Application.ApplicationNumber",
                    })
                  )}
                  789456789
                </h2>
                <h4 className="pt-[1rem] font-semibold text-[1.25rem]">
                  {parse(
                    intl.formatMessage({
                      id: "ServiceMenu.HouseCall.HouseCallHeader",
                    })
                  )}
                </h4>
                <p className="pt-[1rem] font-medium text-[1rem]">
                  {parse(
                    intl.formatMessage({
                      id: "ApplicationForm.Application.FullName",
                    })
                  )}
                  :
                  <span className="font-semibold ml-[0.3rem]">
                    Иван Иван Иванович
                  </span>
                </p>
                <p className="pt-[1rem] font-medium text-[1rem]">
                  {parse(
                    intl.formatMessage({
                      id: "ApplicationForm.Application.IIN",
                    })
                  )}
                  :
                  <span className="font-semibold ml-[0.3rem]">
                    000008500600
                  </span>
                </p>
                <p className="pt-[1rem] font-medium text-[1rem]">
                  {parse(
                    intl.formatMessage({
                      id: "ServiceMenu.HouseCall.Form.Date",
                    })
                  )}
                  :
                  <span className="font-semibold ml-[0.3rem]">
                    08.08.2022 11:00
                  </span>
                </p>
                <p className="pt-[1rem] font-medium text-[1rem]">
                  {parse(
                    intl.formatMessage({
                      id: "ServiceMenu.HouseCall.Form.Time",
                    })
                  )}
                  :
                  <span className="font-semibold ml-[0.3rem]">
                    ул. Жандосова, 1
                  </span>
                </p>
                <button className="mt-[1rem] items-center justify-center border border-black border-1 rounded-[0.56rem] h-[2.75rem] px-[1rem] w-[11rem]">
                  <p className="font-semibold text-[1rem]">
                    {parse(
                      intl.formatMessage({
                        id: "ApplicationForm.Application.CancelButton",
                      })
                    )}
                  </p>
                </button>
              </div>
              <div className="w-30% sm:ml-[0rem] xs:-ml-[1rem]">
                <img src={QR} alt="" className="" />
              </div>
            </div>
            <div className="mt-[2rem]">
              {/*TODO: fix rows below add attribute analysis {title: string, description: string, price: number} */}
              {/*<ApplicationItem />*/}
              {/*<ApplicationItem />*/}
              {/*<ApplicationItem />*/}
              {/*<ApplicationItem />*/}
              {/*House Call Cost*/}
              <div className="sm:pl-[0.5rem] flex sm:flex-row xs:flex-col justify-between  sm:gap-10 xs:gap-3 pt-[0.75rem] justify-between pb-[1rem] border border-b-[#618EE4] border-opacity-50 border-x-white border-t-white border-dotted">
                <p className="sm:w-[55%] xs:w-[100%] font-medium text-[1rem] text-[#001439]">
                  {intl.formatMessage({
                    id: "ServiceMenu.HouseCall.TotalCost",
                  })}
                </p>
                <div className="flex sm:w-[15%] xs:w-[100%] sm:justify-end ">
                  <h4 className="font-semibold text-[1.25rem] text-[#001439]">
                    1 500 ₸
                  </h4>
                </div>
              </div>
            </div>

            <div className="sm:pl-[0.5rem] flex sm:flex-row xs:flex-col justify-between">
              <h2 className="mt-[1rem] font-semibold text-[1.5rem] text-[#001439]">
                {intl.formatMessage({ id: "ServiceMenu.HouseCall.Total" })}
              </h2>
              <h2 className="mt-[1rem] sm:ml-[0.8rem] font-semibold text-[1.5rem] text-[#001439]">
                13 500 ₸
              </h2>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default injectIntl(basket);
